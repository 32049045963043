import type { Location } from '@remix-run/react';
import { useMatches } from '@remix-run/react';
import { useMemo } from 'react';
import DOMPurify from 'isomorphic-dompurify';

import type {
  BrandedPage,
  BrandedPageEdit,
  CardsPage,
  CreditCardIssuersPage,
  CreditCardReviewsPage,
  DataWrapped,
  Footer,
  MegaMenu,
  PointsAndMilesPage,
  ToolsPage,
  User,
  UserDetails,
} from '@cardo/types';

const DEFAULT_REDIRECT = '/';

/**
 * This should be used any time the redirect path is user-provided
 * (Like the query string on our login/signup pages). This avoids
 * open-redirect vulnerabilities.
 * @param {string} to The redirect destination
 * @param {string} defaultRedirect The redirect to use if the to is unsafe.
 */
export function safeRedirect(
  to: FormDataEntryValue | string | null | undefined,
  defaultRedirect: string = DEFAULT_REDIRECT,
) {
  if (!to || typeof to !== 'string') {
    return defaultRedirect;
  }

  if (!to.startsWith('/') || to.startsWith('//')) {
    return defaultRedirect;
  }

  return to;
}

/**
 * This base hook is used in other hooks to quickly search for specific data
 * across all loader data using useMatches.
 * @param {string} id The route id
 * @returns {JSON|undefined} The router data or undefined if not found
 */
export function useMatchesData(
  id: string,
): Record<string, unknown> | undefined {
  const matchingRoutes = useMatches();
  const route = useMemo(
    () => matchingRoutes.find((route) => route.id === id),
    [matchingRoutes, id],
  );
  return route?.data;
}

export function useOptionalUser(): UserDetails | undefined {
  const data = useMatchesData('root') as { user?: User };
  if (!data || !data.user) {
    return undefined;
  }
  return data.user as unknown as UserDetails;
}

export function useFooterContent(): DataWrapped<Footer> | undefined {
  const data = useMatchesData('root') as {
    footer?: DataWrapped<Footer>;
  };
  if (!data || !data.footer) {
    return undefined;
  }
  return data.footer;
}

export function useMegaMenuContent(): DataWrapped<MegaMenu> | undefined {
  const data = useMatchesData('root') as {
    megaMenu?: DataWrapped<MegaMenu>;
  };
  if (!data || !data.megaMenu) {
    return undefined;
  }
  return data.megaMenu;
}

export function useCardsPageData(): CardsPage | undefined {
  const data = useMatchesData('routes/cards') as CardsPage;
  if (!data) {
    return undefined;
  }
  return data;
}

export function useCardIssuersPageData(): CreditCardIssuersPage | undefined {
  const data = useMatchesData('routes/issuers') as CreditCardIssuersPage;
  if (!data) {
    return undefined;
  }
  return data;
}

export function usePointsAndMilesPageData(): PointsAndMilesPage | undefined {
  const data = useMatchesData('routes/points-and-miles') as PointsAndMilesPage;
  if (!data) {
    return undefined;
  }
  return data;
}

export function useToolsPageData(): ToolsPage | undefined {
  const data = useMatchesData('routes/tools') as ToolsPage;
  if (!data) {
    return undefined;
  }
  return data;
}

export function useCreditCardReviewsPageData():
  | CreditCardReviewsPage
  | undefined {
  const data = useMatchesData(
    'routes/credit-cards/reviews',
  ) as CreditCardReviewsPage;
  if (!data) {
    return undefined;
  }
  return data;
}

export function useBrandedPagesData(): BrandedPage[] | undefined {
  const data = useMatchesData(
    'routes/dashboard/branded-pages',
  ) as unknown as BrandedPage[];
  if (!data) {
    return undefined;
  }
  return data;
}

export function useBrandedPageEdit():
  | {
      pageEdit?: BrandedPageEdit;
      page?: BrandedPage;
    }
  | undefined {
  const data = useMatchesData(
    'routes/dashboard/branded-pages/edit',
  ) as unknown as { pageEdit?: BrandedPageEdit; page?: BrandedPage };
  if (!data) {
    return undefined;
  }
  return data;
}

export function formatStripeAmount(amount: number): string {
  return `$${(amount / 100).toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })}`;
}

export function formatCurrency(amount: number, currency?: string): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
  }).format(amount);
}

export function formatDecimal(amount: number, fractionDigits: number): string {
  return amount.toLocaleString('en-US', {
    maximumFractionDigits: fractionDigits || 2,
    minimumFractionDigits: fractionDigits || 2,
  });
}

export function formatInt(amount: number): string {
  return amount.toLocaleString('en-US', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
}

export function getAppUrl(): string | undefined {
  return typeof window !== 'undefined'
    ? window?.ENV?.APP_URL
    : process.env.APP_URL || process.env.VERCEL_URL;
}

export function generateAppUrl(path: string): string {
  let baseUrl = getAppUrl();

  if (baseUrl?.endsWith('/')) {
    baseUrl = baseUrl.slice(0, -1);
  }

  if (path.startsWith('/')) {
    path = path.slice(1);
  }

  return encodeURI(`${baseUrl}/${path}`);
}

export function influencerAllowedOnMediaNetwork(
  mediaNetwork: string | null,
  influencerMediaNetwork:
    | 'qs'
    | 'rv'
    | 'impact'
    | 'qs,rv'
    | 'qs,impact'
    | 'rv,impact'
    | 'all'
    | null,
): boolean {
  const allowedMediaNetworks = influencerMediaNetwork?.split(',') || [];

  if (allowedMediaNetworks.length === 0) {
    return false;
  }

  if (allowedMediaNetworks.includes('all')) {
    return true;
  }

  if (mediaNetwork === null) {
    return false;
  }

  return allowedMediaNetworks.includes(mediaNetwork);
}

export function isCurrentUrl(url: string, location: Location | null): boolean {
  return location?.pathname === url;
}

export function htmlContentIsEmpty(content: string): boolean {
  const contentWithoutTags = DOMPurify.sanitize(content, {
    ALLOWED_TAGS: [],
  })?.trim();
  return !contentWithoutTags || contentWithoutTags === '';
}

export function envPrefixed(name: string) {
  const env =
    typeof window !== 'undefined'
      ? window?.ENV?.NODE_ENV
      : process.env.NODE_ENV;
  return `${env}_${name}`;
}

export function userHasWidgetsEnabled(user: UserDetails): boolean {
  return user?.widgetsEnabled === true;
}
